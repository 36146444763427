import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'userIdInput', 'orderInput', 'orderDiv', 'userNameField', 'userEmailField', 'userImageField', 'contractReviewerRoleSelectContainer',
    'userDetails', 'userDetailIcons', 'userImage', 'contractReviewerSelectContainer',
    'dragIcon', 'buttonsGroup', 'setButton', 'container', 'contractReviewerDescriptionField'
  ];

  static values = {
    data: Array,
    reviewerRoleText: String,
    editorRoleText: String
  }

  static classes = ['hidden'];

  connect () {
    if (this.element.classList.contains('editable')) {
      const containers = this.element.parentElement.querySelectorAll('div.signer-container');
      let count = 0;

      containers.forEach(container => {
        const destroyInput = container.querySelector('input[data-nested-form-target="destroyInput"]');

        if (destroyInput && destroyInput.value !== "1") {
          count++;
        }
      });
      this.orderInputTarget.value = count;
      this.orderDivTarget.innerHTML = count;
      this.orderDivTarget.classList.add(this.hiddenClass);
    } else {
      this.orderDivTarget.classList.remove(this.hiddenClass);
    }
  }

  // ACTIONS

  contractReviewerSelected(e) {
    const userId = this.contractReviewerSelectContainerTarget.querySelector('select').value;
    const role = this.contractReviewerRoleSelectContainerTarget.querySelector('select').value;
    if (userId && userId.trim() !== '' && role && role.trim() !== '') {
      this.setButtonTarget.removeAttribute('disabled');
    } else {
      this.setButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  // PRIVATE

  setUserId(userId) {
    this.userIdInputTarget.value = userId;
  }

  updateFullDataAndShowContractReviewer(userId) {
    const fullData = this.dataValue.map(o => JSON.parse(o));
    const selectedUserData = fullData.find(e => e.id === parseInt(userId, 10));
    this.recountOrder();
    this.showContractReviewer(selectedUserData);
  }

  showContractReviewer(data) {
    this.fillContractReviewerData(data);
    this.swapSelectToForm();
  }

  fillContractReviewerData(data) {
    this.userNameFieldTarget.innerHTML = data.name;
    this.userEmailFieldTarget.innerHTML = data.email;
    this.userImageFieldTarget.src = data.photo;
  }

  swapSelectToForm() {
    this.contractReviewerSelectContainerTarget.classList.add(this.hiddenClass);
    this.contractReviewerRoleSelectContainerTarget.classList.add(this.hiddenClass);
    this.buttonsGroupTarget.classList.add(this.hiddenClass);
    this.element.classList.remove('editable');
    this.userDetailsTarget.classList.remove(this.hiddenClass);
    this.userDetailIconsTarget.classList.remove(this.hiddenClass);
    this.userImageTarget.classList.remove(this.hiddenClass);
    this.dragIconTarget.classList.remove(this.hiddenClass);
    this.orderDivTarget.classList.remove(this.hiddenClass);
    const role = this.contractReviewerRoleSelectContainerTarget.querySelector('select').value;
    if (role === 'editor') {
      this.contractReviewerDescriptionFieldTarget.innerHTML = this.editorRoleTextValue;
    } else {
      this.contractReviewerDescriptionFieldTarget.innerHTML = this.reviewerRoleTextValue;
    }
  }

  cancel(event) {
    event.preventDefault();
    if (this.userNameFieldTarget.innerHTML.trim() !== '') {
      this.swapSelectToForm();
    } else{
      this.containerTarget.remove();
    }
  }

  setContractReviewer(event) {
    event.preventDefault();
    const userId = this.contractReviewerSelectContainerTarget.querySelector('select').value;
    this.setUserId(userId);
    this.updateFullDataAndShowContractReviewer(userId);
  }

  showEditForm() {
    this.contractReviewerRoleSelectContainerTarget.classList.remove(this.hiddenClass);
    this.buttonsGroupTarget.classList.remove(this.hiddenClass);
    this.element.classList.add('editable');
    this.userDetailsTarget.classList.add(this.hiddenClass);
    this.userDetailIconsTarget.classList.add(this.hiddenClass);
    this.userImageTarget.classList.add(this.hiddenClass);
    this.dragIconTarget.classList.add(this.hiddenClass);
    this.orderDivTarget.classList.add(this.hiddenClass);
    this.contractReviewerSelectContainerTarget.classList.remove(this.hiddenClass);
  }

  recountOrder() {
    const containerDivs = Array.from(document.getElementById('contract-reviewers').querySelectorAll('div.signer-container'));
    let count = 0;

    containerDivs.forEach((container, index) => {
      const destroyInput = container.querySelector('input[data-nested-form-target="destroyInput"]');

      if (!destroyInput || destroyInput.value !== "1") {
        container.querySelector('.contract-order-input').value = ++count;
        container.querySelector('.contract-order').innerHTML = count;
      }
    });
  }

  removeSortableTarget() {
    this.containerTarget.removeAttribute('data-sortable-target');
  }
}
