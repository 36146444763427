import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    const targetElement = document.querySelector('[data-action="signer-order-hide#changeContentVisibility"]');

    if (targetElement) {
      const mockEvent = { target: targetElement };
      this.changeContentVisibility(mockEvent);
    }
  }

  changeContentVisibility(event) {
    const selectedValue = event.target.value;
    const containerDivsSet = new Set();

    document.querySelectorAll('.contract-signers').forEach(signersDiv => {
      const signerContainers = signersDiv.querySelectorAll('div.signer-container');
      signerContainers.forEach(container => containerDivsSet.add(container));
    });

    const containerDivs = Array.from(containerDivsSet);

    if (selectedValue === 'false') {
      containerDivs.forEach((container) => {
        container.querySelector('.contract-order').classList.remove('hidden');
        container.querySelector('.drag-signer-icon').classList.remove('hidden');
      });
    } else {
      containerDivs.forEach((container) => {
        container.querySelector('.contract-order').classList.add('hidden');
        container.querySelector('.drag-signer-icon').classList.add('hidden');
      });
    }
  }
}
