import { Controller } from '@hotwired/stimulus';
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".drag-signer-icon",
      animation: 150,
      onEnd: this.reorder.bind(this),
    });
  }

  reorder(event) {
    const containers = this.containerTargets;

    containers.forEach((container, index) => {
      container.querySelector('.contract-order-input').value = index + 1;
      container.querySelector('.contract-order').innerHTML = index + 1;
    });
  }
}
