import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'container', 'body', 'showMoreButton', 'showLessButton', 'noteField'
  ];

  showMore() {
    this.showMoreButtonTarget.classList.add('hidden');
    this.showLessButtonTarget.classList.remove('hidden');
    this.bodyTarget.classList.remove('collapsed-contract');
    this.containerTarget.classList.remove('collapsed-contract-container');
  }

  showLess() {
    this.showLessButtonTarget.classList.add('hidden');
    this.showMoreButtonTarget.classList.remove('hidden');
    this.bodyTarget.classList.add('collapsed-contract');
    this.containerTarget.classList.add('collapsed-contract-container');
  }

  toggleNoteField(event) {
    const selectedValue = event.target.value;
    if (selectedValue === "rejected") {
      this.showNoteField();
    } else {
      this.hideNoteField();
    }
  }

  showNoteField() {
    this.noteFieldTarget.classList.remove("hidden");
    this.noteFieldTarget.classList.add("visible");
  }

  hideNoteField() {
    this.noteFieldTarget.classList.add("hidden");
    this.noteFieldTarget.classList.remove("visible");
  }
}
