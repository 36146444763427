/* rails-erb-loader-dependencies ../config/routes */
var routes = {};

(function() {
  /*
File generated by js-routes 1.4.9
Based on Rails 6.1.5.1 routes of Elvium::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {"locale":"en"},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_request_edit_request => (/:locale)/edit_requests/:id/accept_request(.:format)
  // function(id, options)
  accept_request_edit_request_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"edit_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// add_demo_candidates_admin_position => /admin/positions/:id/add_demo_candidates(.:format)
  // function(id, options)
  add_demo_candidates_admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_demo_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// add_demo_candidates_position => (/:locale)/positions/:id/add_demo_candidates(.:format)
  // function(id, options)
  add_demo_candidates_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_demo_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// admin_documents => /admin/documents(.:format)
  // function(options)
  admin_documents_path: Utils.route([["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_position => /admin/positions/:id(.:format)
  // function(id, options)
  admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_positions => /admin/positions(.:format)
  // function(options)
  admin_positions_path: Utils.route([["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_root => /admin(.:format)
  // function(options)
  admin_root_path: Utils.route([["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_docs.root => /swagger/
  // function(options)
  api_docs_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"swagger",false]],[7,"/",false]]),
// archive_position => (/:locale)/positions/:id/archive(.:format)
  // function(id, options)
  archive_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// archived_positions => (/:locale)/archived(.:format)
  // function(options)
  archived_positions_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// artificial_saved_profile_comment => (/:locale)/artificial_saved_profiles/:artificial_saved_profile_id/comments/:id(.:format)
  // function(artificial_saved_profile_id, id, options)
  artificial_saved_profile_comment_path: Utils.route([["locale",false],["artificial_saved_profile_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"artificial_saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// artificial_saved_profile_comments => (/:locale)/artificial_saved_profiles/:artificial_saved_profile_id/comments(.:format)
  // function(artificial_saved_profile_id, options)
  artificial_saved_profile_comments_path: Utils.route([["locale",false],["artificial_saved_profile_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"artificial_saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// attach_document_position => (/:locale)/positions/:id/attach_document(.:format)
  // function(id, options)
  attach_document_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"attach_document",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// availability_date => (/:locale)/availability_dates/:id(.:format)
  // function(id, options)
  availability_date_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"availability_dates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// availability_dates => (/:locale)/availability_dates(.:format)
  // function(options)
  availability_dates_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"availability_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// batch_action_admin_documents => /admin/documents/batch_action(.:format)
  // function(options)
  batch_action_admin_documents_path: Utils.route([["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_action_admin_positions => /admin/positions/batch_action(.:format)
  // function(options)
  batch_action_admin_positions_path: Utils.route([["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[6,"batch_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// brief_feedback_form => (/:locale)/feedback_forms/:id/brief(.:format)
  // function(id, options)
  brief_feedback_form_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"feedback_forms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"brief",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// calendar_candidate_interview_date => (/:locale)/c/id/candidate/:id/:secret(.:format)
  // function(id, secret, options)
  calendar_candidate_interview_date_path: Utils.route([["locale",false],["id",true],["secret",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"id",false],[2,[7,"/",false],[2,[6,"candidate",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"secret",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// calendar_mine_interview_dates => (/:locale)/c/id/mine/:secret(.:format)
  // function(secret, options)
  calendar_mine_interview_dates_path: Utils.route([["locale",false],["secret",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"id",false],[2,[7,"/",false],[2,[6,"mine",false],[2,[7,"/",false],[2,[3,"secret",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// calendar_organization_interview_dates => (/:locale)/c/id/org/:secret(.:format)
  // function(secret, options)
  calendar_organization_interview_dates_path: Utils.route([["locale",false],["secret",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"id",false],[2,[7,"/",false],[2,[6,"org",false],[2,[7,"/",false],[2,[3,"secret",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// calendar_show_ics_interview_dates => (/:locale)/c/id/show(.:format)
  // function(options)
  calendar_show_ics_interview_dates_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"id",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// cancel_pending_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/cancel_pending(.:format)
  // function(position_id, id, options)
  cancel_pending_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// cancel_pending_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/cancel_pending(.:format)
  // function(position_id, id, options)
  cancel_pending_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// candidate_copy_contract_document => (/:locale)/contract_documents/:id/candidate_copy(.:format)
  // function(id, options)
  candidate_copy_contract_document_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"candidate_copy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// candidate_root => /(:locale)(.:format)
  // function(options)
  candidate_root_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[1,[3,"locale",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// candidates_document_requests => (/:locale)/candidates/document_requests(.:format)
  // function(options)
  candidates_document_requests_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"candidates",false],[2,[7,"/",false],[2,[6,"document_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// catalyst_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/catalyst_integration(.:format)
  // function(position_id, id, options)
  catalyst_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"catalyst_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// catalyst_integration_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/catalyst_integration(.:format)
  // function(position_id, id, options)
  catalyst_integration_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"catalyst_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// categories => (/:locale)/categories(.:format)
  // function(options)
  categories_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// category => (/:locale)/categories/:id(.:format)
  // function(id, options)
  category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// category_artificial_saved_profile => (/:locale)/categories/:category_id/artificial_saved_profiles/:id(.:format)
  // function(category_id, id, options)
  category_artificial_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// category_saved_profile => (/:locale)/categories/:category_id/saved_profiles/:id(.:format)
  // function(category_id, id, options)
  category_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// category_saved_profiles => (/:locale)/categories/:category_id/saved_profiles(.:format)
  // function(category_id, options)
  category_saved_profiles_path: Utils.route([["locale",false],["category_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// change_file_visibility_hr_employees_datum => (/:locale)/hr/employees_data/:id/change_file_visibility(.:format)
  // function(id, options)
  change_file_visibility_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_file_visibility",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// change_hide_rejected_position => (/:locale)/positions/:id/change_hide_rejected(.:format)
  // function(id, options)
  change_hide_rejected_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_hide_rejected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// change_mark_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/change_mark(.:format)
  // function(position_id, id, options)
  change_mark_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_mark",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// change_mark_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/change_mark(.:format)
  // function(position_id, id, options)
  change_mark_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_mark",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// change_maybe_flag_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/change_maybe_flag(.:format)
  // function(position_id, id, options)
  change_maybe_flag_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_maybe_flag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// change_maybe_flag_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/change_maybe_flag(.:format)
  // function(position_id, id, options)
  change_maybe_flag_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_maybe_flag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// change_owner_hr_person => (/:locale)/hr/people/:id/change_owner(.:format)
  // function(id, options)
  change_owner_hr_person_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_owner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// chat_category_saved_profile => (/:locale)/categories/:category_id/saved_profiles/:id/chat(.:format)
  // function(category_id, id, options)
  chat_category_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"chat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// check_email_uniqueness_hr_employees_data => (/:locale)/hr/employees_data/check_email_uniqueness(.:format)
  // function(options)
  check_email_uniqueness_hr_employees_data_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[6,"check_email_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// check_internal_id_uniqueness_hr_employees_data => (/:locale)/hr/employees_data/check_internal_id_uniqueness(.:format)
  // function(options)
  check_internal_id_uniqueness_hr_employees_data_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[6,"check_internal_id_uniqueness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// check_transfer_conditions_position_application_transfer_operations => (/:locale)/positions/:position_id/application_transfer_operations/check_transfer_conditions(.:format)
  // function(position_id, options)
  check_transfer_conditions_position_application_transfer_operations_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"application_transfer_operations",false],[2,[7,"/",false],[2,[6,"check_transfer_conditions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// checklist => (/:locale)/checklists/:id(.:format)
  // function(id, options)
  checklist_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// checklist_evaluation => (/:locale)/checklist_evaluations/:id(.:format)
  // function(id, options)
  checklist_evaluation_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklist_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// checklist_evaluations => (/:locale)/checklist_evaluations(.:format)
  // function(options)
  checklist_evaluations_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklist_evaluations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// checklist_task_evaluation => (/:locale)/checklist_task_evaluations/:id(.:format)
  // function(id, options)
  checklist_task_evaluation_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklist_task_evaluations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// checklists => (/:locale)/checklists(.:format)
  // function(options)
  checklists_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// clone_position_position => (/:locale)/positions/:id/clone_position(.:format)
  // function(id, options)
  clone_position_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// close_position_position => (/:locale)/positions/:id/close_position(.:format)
  // function(id, options)
  close_position_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// closed_positions => (/:locale)/closed(.:format)
  // function(options)
  closed_positions_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"closed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// communicate_all_position => (/:locale)/positions/:id/communicate_all(.:format)
  // function(id, options)
  communicate_all_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"communicate_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// confirm_destroy_position_candidates_application => (/:locale)/positions/:position_id/c/applications/:id/confirm_destroy(.:format)
  // function(position_id, id, options)
  confirm_destroy_position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// confirm_keeping_position_candidates_application => (/:locale)/positions/:position_id/c/applications/:id/confirm_keeping(.:format)
  // function(position_id, id, options)
  confirm_keeping_position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_keeping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// contract_document => (/:locale)/contract_documents/:id(.:format)
  // function(id, options)
  contract_document_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// contract_documents => (/:locale)/contract_documents(.:format)
  // function(options)
  contract_documents_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// contract_request => (/:locale)/contract_requests/:id(.:format)
  // function(id, options)
  contract_request_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// contract_requests => (/:locale)/contract_requests(.:format)
  // function(options)
  contract_requests_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// contract_signing => (/:locale)/contract_signings/:id(.:format)
  // function(id, options)
  contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// contract_signings => (/:locale)/contract_signings(.:format)
  // function(options)
  contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// create_from_excel_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/create_from_excel(.:format)
  // function(position_id, options)
  create_from_excel_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"create_from_excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// create_from_pdf_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/create_from_pdf(.:format)
  // function(position_id, options)
  create_from_pdf_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"create_from_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// create_profile_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/create_profile(.:format)
  // function(position_id, options)
  create_profile_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"create_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// create_subcategory_category => (/:locale)/categories/:id/create_subcategory(.:format)
  // function(id, options)
  create_subcategory_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_subcategory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// custom_contract_tag => (/:locale)/custom_contract_tags/:id(.:format)
  // function(id, options)
  custom_contract_tag_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"custom_contract_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// custom_contract_tags => (/:locale)/custom_contract_tags(.:format)
  // function(options)
  custom_contract_tags_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"custom_contract_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// custom_domain_root => /
  // function(options)
  custom_domain_root_path: Utils.route([], {}, [7,"/",false]),
// delete_file_hr_employees_datum => (/:locale)/hr/employees_data/:id/delete_file(.:format)
  // function(id, options)
  delete_file_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// delete_photo_hr_employees_datum => (/:locale)/hr/employees_data/:id/delete_photo(.:format)
  // function(id, options)
  delete_photo_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// destroy_all_position_candidates_position_approaches => (/:locale)/positions/:position_id/c/position_approaches/destroy_all(.:format)
  // function(position_id, options)
  destroy_all_position_candidates_position_approaches_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"position_approaches",false],[2,[7,"/",false],[2,[6,"destroy_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// destroy_cache_documents => (/:locale)/documents/destroy_cache(.:format)
  // function(options)
  destroy_cache_documents_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"destroy_cache",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// destroy_document_position => (/:locale)/positions/:id/destroy_document/:document_id(.:format)
  // function(id, document_id, options)
  destroy_document_position_path: Utils.route([["locale",false],["id",true],["document_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_document",false],[2,[7,"/",false],[2,[3,"document_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// destroy_hr_employee_session => (/:locale)/hr/employees/sign_out(.:format)
  // function(options)
  destroy_hr_employee_session_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// destroy_logo_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/destroy_logo(.:format)
  // function(position_id, options)
  destroy_logo_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"destroy_logo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// destroy_photo_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/destroy_photo(.:format)
  // function(position_id, id, options)
  destroy_photo_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// destroy_requested_position_recruiters_application_document => (/:locale)/positions/:position_id/r/applications/:application_id/documents/:id/destroy_requested(.:format)
  // function(position_id, application_id, id, options)
  destroy_requested_position_recruiters_application_document_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_requested",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
// destroy_requested_position_recruiters_artificial_candidate_document => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/documents/:id/destroy_requested(.:format)
  // function(position_id, artificial_candidate_id, id, options)
  destroy_requested_position_recruiters_artificial_candidate_document_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_requested",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
// digital_marketing_statistics => (/:locale)/statistics/digital_marketing(.:format)
  // function(options)
  digital_marketing_statistics_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"statistics",false],[2,[7,"/",false],[2,[6,"digital_marketing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// discard_remaining_position => (/:locale)/positions/:id/discard_remaining(.:format)
  // function(id, options)
  discard_remaining_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"discard_remaining",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// documents_category_artificial_saved_profile => (/:locale)/categories/:category_id/artificial_saved_profiles/:id/documents(.:format)
  // function(category_id, id, options)
  documents_category_artificial_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// documents_category_saved_profile => (/:locale)/categories/:category_id/saved_profiles/:id/documents(.:format)
  // function(category_id, id, options)
  documents_category_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// documents_contract_signings => (/:locale)/contract_signings/documents(.:format)
  // function(options)
  documents_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// download_position_recruiters_applications_summary => (/:locale)/positions/:position_id/r/applications_summaries/:id/download(.:format)
  // function(position_id, id, options)
  download_position_recruiters_applications_summary_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications_summaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// draft_positions => (/:locale)/drafts(.:format)
  // function(options)
  draft_positions_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"drafts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// duplicate_admin_position => /admin/positions/:id/duplicate(.:format)
  // function(id, options)
  duplicate_admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// duplicate_checklist => (/:locale)/checklists/:id/duplicate(.:format)
  // function(id, options)
  duplicate_checklist_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_admin_position => /admin/positions/:id/edit(.:format)
  // function(id, options)
  edit_admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_artificial_saved_profile_comment => (/:locale)/artificial_saved_profiles/:artificial_saved_profile_id/comments/:id/edit(.:format)
  // function(artificial_saved_profile_id, id, options)
  edit_artificial_saved_profile_comment_path: Utils.route([["locale",false],["artificial_saved_profile_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"artificial_saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_brief_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/edit_brief(.:format)
  // function(position_id, id, options)
  edit_brief_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_brief",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_brief_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/edit_brief(.:format)
  // function(position_id, id, options)
  edit_brief_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_brief",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_category => (/:locale)/categories/:id/edit(.:format)
  // function(id, options)
  edit_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_checklist => (/:locale)/checklists/:id/edit(.:format)
  // function(id, options)
  edit_checklist_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_contract_document => (/:locale)/contract_documents/:id/edit(.:format)
  // function(id, options)
  edit_contract_document_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_contract_signing => (/:locale)/contract_signings/:id/edit(.:format)
  // function(id, options)
  edit_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_custom_contract_tag => (/:locale)/custom_contract_tags/:id/edit(.:format)
  // function(id, options)
  edit_custom_contract_tag_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"custom_contract_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_feedback_form => (/:locale)/feedback_forms/:id/edit(.:format)
  // function(id, options)
  edit_feedback_form_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"feedback_forms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_hr_department => (/:locale)/hr/departments/:id/edit(.:format)
  // function(id, options)
  edit_hr_department_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_hr_employee_password => (/:locale)/hr/employees/password/edit(.:format)
  // function(options)
  edit_hr_employee_password_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_hr_organization => (/:locale)/hr/organizations/:id/edit(.:format)
  // function(id, options)
  edit_hr_organization_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_hr_permission => (/:locale)/hr/permissions/:id/edit(.:format)
  // function(id, options)
  edit_hr_permission_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_hr_person => (/:locale)/hr/people/:id/edit(.:format)
  // function(id, options)
  edit_hr_person_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_hr_team => (/:locale)/hr/teams/:id/edit(.:format)
  // function(id, options)
  edit_hr_team_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_interview_time_position_rounds => (/:locale)/positions/:position_id/rounds/edit_interview_time(.:format)
  // function(position_id, options)
  edit_interview_time_position_rounds_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[6,"edit_interview_time",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_media_module_settings_position => (/:locale)/positions/:id/edit_media_module_settings(.:format)
  // function(id, options)
  edit_media_module_settings_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_media_module_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_organization_saved_profile => (/:locale)/organizations/:organization_id/saved_profiles/:id/edit(.:format)
  // function(organization_id, id, options)
  edit_organization_saved_profile_path: Utils.route([["locale",false],["organization_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_position => (/:locale)/positions/:id/edit(.:format)
  // function(id, options)
  edit_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// edit_position_application_transfer_operation => (/:locale)/positions/:position_id/application_transfer_operations/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_application_transfer_operation_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"application_transfer_operations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_position_candidate_email => (/:locale)/positions/:position_id/candidate_email/edit(.:format)
  // function(position_id, options)
  edit_position_candidate_email_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"candidate_email",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_position_candidates_application => (/:locale)/positions/:position_id/c/applications/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_position_email_customization => (/:locale)/positions/:position_id/email_customizations/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_email_customization_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"email_customizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_position_interview_reminder => (/:locale)/positions/:position_id/interview_reminders/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_interview_reminder_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_reminders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_position_job_posting => (/:locale)/positions/:position_id/job_posting/edit(.:format)
  // function(position_id, options)
  edit_position_job_posting_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"job_posting",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_position_jobindex_publication => (/:locale)/positions/:position_id/jobindex_publication/edit(.:format)
  // function(position_id, options)
  edit_position_jobindex_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobindex_publication",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/edit(.:format)
  // function(position_id, options)
  edit_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// edit_position_recruiters_application_document => (/:locale)/positions/:position_id/r/applications/:application_id/documents/:id/edit(.:format)
  // function(position_id, application_id, id, options)
  edit_position_recruiters_application_document_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
// edit_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// edit_position_recruiters_artificial_candidate_document => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/documents/:id/edit(.:format)
  // function(position_id, artificial_candidate_id, id, options)
  edit_position_recruiters_artificial_candidate_document_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]),
// edit_position_round => (/:locale)/positions/:position_id/rounds/:id/edit(.:format)
  // function(position_id, id, options)
  edit_position_round_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_requests => (/:locale)/edit_requests(.:format)
  // function(options)
  edit_requests_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"edit_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// edit_saved_profile_comment => (/:locale)/saved_profiles/:saved_profile_id/comments/:id/edit(.:format)
  // function(saved_profile_id, id, options)
  edit_saved_profile_comment_path: Utils.route([["locale",false],["saved_profile_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// edit_video_request => (/:locale)/video_requests/:id/edit(.:format)
  // function(id, options)
  edit_video_request_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"video_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// editor_review_contract_signing => (/:locale)/contract_signings/:id/editor_review(.:format)
  // function(id, options)
  editor_review_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"editor_review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// email_logs_position_application_event_logs => (/:locale)/positions/:position_id/applications/:application_id/event_logs/email_logs(.:format)
  // function(position_id, application_id, options)
  email_logs_position_application_event_logs_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"event_logs",false],[2,[7,"/",false],[2,[6,"email_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// email_logs_position_artificial_candidate_event_logs => (/:locale)/positions/:position_id/artificial_candidates/:artificial_candidate_id/event_logs/email_logs(.:format)
  // function(position_id, artificial_candidate_id, options)
  email_logs_position_artificial_candidate_event_logs_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"event_logs",false],[2,[7,"/",false],[2,[6,"email_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// events_availability_dates => (/:locale)/availability_dates/events(.:format)
  // function(options)
  events_availability_dates_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"availability_dates",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// execute_manual_remerging_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/execute_manual_remerging(.:format)
  // function(position_id, id, options)
  execute_manual_remerging_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"execute_manual_remerging",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// execute_manual_remerging_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/execute_manual_remerging(.:format)
  // function(position_id, id, options)
  execute_manual_remerging_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"execute_manual_remerging",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// export_all_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/export_all(.:format)
  // function(position_id, id, options)
  export_all_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// export_all_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/export_all(.:format)
  // function(position_id, id, options)
  export_all_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// export_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/export(.:format)
  // function(position_id, id, options)
  export_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// export_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/export(.:format)
  // function(position_id, id, options)
  export_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// extra_data_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/extra_data(.:format)
  // function(position_id, id, options)
  extra_data_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"extra_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// extra_data_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/extra_data(.:format)
  // function(position_id, id, options)
  extra_data_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"extra_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// extra_time_slots_availability_date => (/:locale)/availability_dates/:id/extra_time_slots(.:format)
  // function(id, options)
  extra_time_slots_availability_date_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"availability_dates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"extra_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// extra_time_slots_position => (/:locale)/positions/:id/extra_time_slots(.:format)
  // function(id, options)
  extra_time_slots_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"extra_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// extra_time_slots_position_interview_date => (/:locale)/positions/:position_id/interview_dates/:id/extra_time_slots(.:format)
  // function(position_id, id, options)
  extra_time_slots_position_interview_date_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_dates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"extra_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// feedback_form => (/:locale)/feedback_forms/:id(.:format)
  // function(id, options)
  feedback_form_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"feedback_forms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// feedback_forms => (/:locale)/feedback_forms(.:format)
  // function(options)
  feedback_forms_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"feedback_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// filter_positions_invites => (/:locale)/invites/filter_positions(.:format)
  // function(options)
  filter_positions_invites_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"invites",false],[2,[7,"/",false],[2,[6,"filter_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// filter_positions_recruiters => (/:locale)/recruiters/filter_positions(.:format)
  // function(options)
  filter_positions_recruiters_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"recruiters",false],[2,[7,"/",false],[2,[6,"filter_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// find_states_addresses => (/:locale)/addresses/find_states(.:format)
  // function(options)
  find_states_addresses_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"addresses",false],[2,[7,"/",false],[2,[6,"find_states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// generate_time_slots_availability_date => (/:locale)/availability_dates/:id/generate_time_slots(.:format)
  // function(id, options)
  generate_time_slots_availability_date_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"availability_dates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generate_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// generate_time_slots_position_interview_date => (/:locale)/positions/:position_id/interview_dates/:id/generate_time_slots(.:format)
  // function(position_id, id, options)
  generate_time_slots_position_interview_date_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_dates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generate_time_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// hr_demo_data => (/:locale)/hr/demo_data(.:format)
  // function(options)
  hr_demo_data_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"demo_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_department => (/:locale)/hr/departments/:id(.:format)
  // function(id, options)
  hr_department_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_departments => (/:locale)/hr/departments(.:format)
  // function(options)
  hr_departments_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"departments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_employee_password => (/:locale)/hr/employees/password(.:format)
  // function(options)
  hr_employee_password_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_employee_session => (/:locale)/hr/employees/sign_in(.:format)
  // function(options)
  hr_employee_session_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_employees_datum => (/:locale)/hr/employees_data/:id(.:format)
  // function(id, options)
  hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_employees_datum_bank_account => (/:locale)/hr/employees_data/:employees_datum_id/bank_account/:id(.:format)
  // function(employees_datum_id, id, options)
  hr_employees_datum_bank_account_path: Utils.route([["locale",false],["employees_datum_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"employees_datum_id",false],[2,[7,"/",false],[2,[6,"bank_account",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// hr_employees_datum_bank_account_index => (/:locale)/hr/employees_data/:employees_datum_id/bank_account(.:format)
  // function(employees_datum_id, options)
  hr_employees_datum_bank_account_index_path: Utils.route([["locale",false],["employees_datum_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"employees_datum_id",false],[2,[7,"/",false],[2,[6,"bank_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// hr_employees_datum_emergency_contact => (/:locale)/hr/employees_data/:employees_datum_id/emergency_contact/:id(.:format)
  // function(employees_datum_id, id, options)
  hr_employees_datum_emergency_contact_path: Utils.route([["locale",false],["employees_datum_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"employees_datum_id",false],[2,[7,"/",false],[2,[6,"emergency_contact",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// hr_employees_datum_emergency_contact_index => (/:locale)/hr/employees_data/:employees_datum_id/emergency_contact(.:format)
  // function(employees_datum_id, options)
  hr_employees_datum_emergency_contact_index_path: Utils.route([["locale",false],["employees_datum_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"employees_datum_id",false],[2,[7,"/",false],[2,[6,"emergency_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// hr_module_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/hr_module_integration(.:format)
  // function(position_id, id, options)
  hr_module_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hr_module_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// hr_organization => (/:locale)/hr/organizations/:id(.:format)
  // function(id, options)
  hr_organization_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_organizations => (/:locale)/hr/organizations(.:format)
  // function(options)
  hr_organizations_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"organizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_people => (/:locale)/hr/people(.:format)
  // function(options)
  hr_people_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_permission => (/:locale)/hr/permissions/:id(.:format)
  // function(id, options)
  hr_permission_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_permissions => (/:locale)/hr/permissions(.:format)
  // function(options)
  hr_permissions_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_person => (/:locale)/hr/people/:id(.:format)
  // function(id, options)
  hr_person_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_reports => (/:locale)/hr/reports(.:format)
  // function(options)
  hr_reports_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_team => (/:locale)/hr/teams/:id(.:format)
  // function(id, options)
  hr_team_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// hr_teams => (/:locale)/hr/teams(.:format)
  // function(options)
  hr_teams_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// hr_tree_team => (/:locale)/hr/tree/teams/:id(.:format)
  // function(id, options)
  hr_tree_team_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"tree",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// hr_tree_teams => (/:locale)/hr/tree/teams(.:format)
  // function(options)
  hr_tree_teams_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"tree",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// inactive_hr_people => (/:locale)/hr/people/inactive(.:format)
  // function(options)
  inactive_hr_people_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"inactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// invite_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/invite(.:format)
  // function(position_id, id, options)
  invite_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// invite_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/invite(.:format)
  // function(position_id, id, options)
  invite_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// invite_user_category => (/:locale)/categories/:id/invite_user(.:format)
  // function(id, options)
  invite_user_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// lit.synchronize_source => /lit/sources/:id/synchronize(.:format)
  // function(id, options)
  lit_synchronize_source_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lit",false]],[7,"/",false]],[2,[6,"sources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"synchronize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lit.root => /lit/
  // function(options)
  lit_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"lit",false]],[7,"/",false]]),
// load_categories_categories => (/:locale)/categories/load_categories(.:format)
  // function(options)
  load_categories_categories_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"load_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// load_responsibles_contract_requests => (/:locale)/contract_requests/load_responsibles(.:format)
  // function(options)
  load_responsibles_contract_requests_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_requests",false],[2,[7,"/",false],[2,[6,"load_responsibles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// log_category_artificial_saved_profile => (/:locale)/categories/:category_id/artificial_saved_profiles/:id/log(.:format)
  // function(category_id, id, options)
  log_category_artificial_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"log",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// log_category_saved_profile => (/:locale)/categories/:category_id/saved_profiles/:id/log(.:format)
  // function(category_id, id, options)
  log_category_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"log",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// logout_root => /
  // function(options)
  logout_root_path: Utils.route([], {"host":"app.elvium.com"}, [7,"/",false]),
// mass_action_position => (/:locale)/positions/:id/mass_action(.:format)
  // function(id, options)
  mass_action_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mass_action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// mass_actions_position_recruiters_applications => (/:locale)/positions/:position_id/r/applications/mass_actions(.:format)
  // function(position_id, options)
  mass_actions_position_recruiters_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"mass_actions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// merge_fields_contract_signings => (/:locale)/contract_signings/merge_fields(.:format)
  // function(options)
  merge_fields_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"merge_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// move_to_category_category => (/:locale)/categories/:id/move_to_category(.:format)
  // function(id, options)
  move_to_category_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_category",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// move_to_organization_admin_position => /admin/positions/:id/move_to_organization(.:format)
  // function(id, options)
  move_to_organization_admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_organization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// move_to_round_position_round => (/:locale)/positions/:position_id/rounds/:id/move_to_round(.:format)
  // function(position_id, id, options)
  move_to_round_position_round_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_round",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_artificial_saved_profile_comment => (/:locale)/artificial_saved_profiles/:artificial_saved_profile_id/comments/new(.:format)
  // function(artificial_saved_profile_id, options)
  new_artificial_saved_profile_comment_path: Utils.route([["locale",false],["artificial_saved_profile_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"artificial_saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_category => (/:locale)/categories/new(.:format)
  // function(options)
  new_category_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_checklist => (/:locale)/checklists/new(.:format)
  // function(options)
  new_checklist_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_checklist_evaluation => (/:locale)/checklist_evaluations/new(.:format)
  // function(options)
  new_checklist_evaluation_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"checklist_evaluations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_communicate_all_position => (/:locale)/positions/:id/new_communicate_all(.:format)
  // function(id, options)
  new_communicate_all_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"new_communicate_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_contract_document => (/:locale)/contract_documents/new(.:format)
  // function(options)
  new_contract_document_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_contract_signing => (/:locale)/contract_signings/new(.:format)
  // function(options)
  new_contract_signing_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_custom_contract_tag => (/:locale)/custom_contract_tags/new(.:format)
  // function(options)
  new_custom_contract_tag_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"custom_contract_tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_feedback_form => (/:locale)/feedback_forms/new(.:format)
  // function(options)
  new_feedback_form_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"feedback_forms",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_from_excel_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/new_from_excel(.:format)
  // function(position_id, options)
  new_from_excel_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new_from_excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_from_pdf_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/new_from_pdf(.:format)
  // function(position_id, options)
  new_from_pdf_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new_from_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_hr_department => (/:locale)/hr/departments/new(.:format)
  // function(options)
  new_hr_department_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_hr_employee_password => (/:locale)/hr/employees/password/new(.:format)
  // function(options)
  new_hr_employee_password_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_hr_employee_session => (/:locale)/hr/employees/sign_in(.:format)
  // function(options)
  new_hr_employee_session_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_hr_organization => (/:locale)/hr/organizations/new(.:format)
  // function(options)
  new_hr_organization_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_hr_permission => (/:locale)/hr/permissions/new(.:format)
  // function(options)
  new_hr_permission_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_hr_person => (/:locale)/hr/people/new(.:format)
  // function(options)
  new_hr_person_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_hr_team => (/:locale)/hr/teams/new(.:format)
  // function(options)
  new_hr_team_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_move_to_round_position_round => (/:locale)/positions/:position_id/rounds/:id/new_move_to_round(.:format)
  // function(position_id, id, options)
  new_move_to_round_position_round_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"new_move_to_round",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_notify_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/new_notify(.:format)
  // function(position_id, options)
  new_notify_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new_notify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_organization_saved_profile => (/:locale)/organizations/:organization_id/saved_profiles/new(.:format)
  // function(organization_id, options)
  new_organization_saved_profile_path: Utils.route([["locale",false],["organization_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position => (/:locale)/positions/new(.:format)
  // function(options)
  new_position_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_position_application => (/:locale)/positions/:position_id/applications/new(.:format)
  // function(position_id, options)
  new_position_application_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_application_transfer_operation => (/:locale)/positions/:position_id/application_transfer_operations/new(.:format)
  // function(position_id, options)
  new_position_application_transfer_operation_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"application_transfer_operations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_candidate_email => (/:locale)/positions/:position_id/candidate_email/new(.:format)
  // function(position_id, options)
  new_position_candidate_email_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"candidate_email",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_candidates_application => (/:locale)/positions/:position_id/c/applications/new(.:format)
  // function(position_id, options)
  new_position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_position_email_customization => (/:locale)/positions/:position_id/email_customizations/new(.:format)
  // function(position_id, options)
  new_position_email_customization_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"email_customizations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_interview_date => (/:locale)/positions/:position_id/interview_dates/new(.:format)
  // function(position_id, options)
  new_position_interview_date_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_dates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_interview_reminder => (/:locale)/positions/:position_id/interview_reminders/new(.:format)
  // function(position_id, options)
  new_position_interview_reminder_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_reminders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_jobindex_publication => (/:locale)/positions/:position_id/jobindex_publication/new(.:format)
  // function(position_id, options)
  new_position_jobindex_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobindex_publication",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/new(.:format)
  // function(position_id, options)
  new_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_position_round => (/:locale)/positions/:position_id/rounds/new(.:format)
  // function(position_id, options)
  new_position_round_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_position_simplyjob_publication => (/:locale)/positions/:position_id/simplyjob_publication/new(.:format)
  // function(position_id, options)
  new_position_simplyjob_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"simplyjob_publication",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_profile_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/new_profile(.:format)
  // function(position_id, options)
  new_profile_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_reopen_position => (/:locale)/positions/:id/new_reopen(.:format)
  // function(id, options)
  new_reopen_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"new_reopen",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_request_documents_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/new_request_documents(.:format)
  // function(position_id, options)
  new_request_documents_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"new_request_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// new_saved_profile => (/:locale)/saved_profiles/new(.:format)
  // function(options)
  new_saved_profile_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// new_saved_profile_comment => (/:locale)/saved_profiles/:saved_profile_id/comments/new(.:format)
  // function(saved_profile_id, options)
  new_saved_profile_comment_path: Utils.route([["locale",false],["saved_profile_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// new_video_request => (/:locale)/video_requests/new(.:format)
  // function(options)
  new_video_request_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"video_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// not_found_root => /
  // function(options)
  not_found_root_path: Utils.route([], {}, [7,"/",false]),
// notify_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/notify(.:format)
  // function(position_id, options)
  notify_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"notify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// onboarding_hr_person => (/:locale)/hr/people/:id/onboarding(.:format)
  // function(id, options)
  onboarding_hr_person_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"onboarding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// organization_redirect_organization => (/:locale)/organizations/:id/organization_redirect(.:format)
  // function(id, options)
  organization_redirect_organization_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"organization_redirect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// organization_saved_profile => (/:locale)/organizations/:organization_id/saved_profiles/:id(.:format)
  // function(organization_id, id, options)
  organization_saved_profile_path: Utils.route([["locale",false],["organization_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// organization_saved_profiles => (/:locale)/organizations/:organization_id/saved_profiles(.:format)
  // function(organization_id, options)
  organization_saved_profiles_path: Utils.route([["locale",false],["organization_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"organization_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// play_video_position_application => (/:locale)/positions/:position_id/applications/:id/play_video(.:format)
  // function(position_id, id, options)
  play_video_position_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"play_video",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// play_video_position_artificial_candidate => (/:locale)/positions/:position_id/artificial_candidates/:id/play_video(.:format)
  // function(position_id, id, options)
  play_video_position_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"play_video",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position => (/:locale)/positions/:id(.:format)
  // function(id, options)
  position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// position_application_chat_messages => (/:locale)/positions/:position_id/applications/:application_id/chat(.:format)
  // function(position_id, application_id, options)
  position_application_chat_messages_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"chat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_application_checklist_evaluation => (/:locale)/positions/:position_id/applications/:application_id/checklists/:id(.:format)
  // function(position_id, application_id, id, options)
  position_application_checklist_evaluation_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_application_checklist_evaluations => (/:locale)/positions/:position_id/applications/:application_id/checklists(.:format)
  // function(position_id, application_id, options)
  position_application_checklist_evaluations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"checklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_application_document => (/:locale)/positions/:position_id/applications/:application_id/documents/:id(.:format)
  // function(position_id, application_id, id, options)
  position_application_document_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_application_transfer_operation => (/:locale)/positions/:position_id/application_transfer_operations/:id(.:format)
  // function(position_id, id, options)
  position_application_transfer_operation_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"application_transfer_operations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_application_transfer_operations => (/:locale)/positions/:position_id/application_transfer_operations(.:format)
  // function(position_id, options)
  position_application_transfer_operations_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"application_transfer_operations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_artificial_candidate_checklist_evaluation => (/:locale)/positions/:position_id/artificial_candidates/:artificial_candidate_id/checklists/:id(.:format)
  // function(position_id, artificial_candidate_id, id, options)
  position_artificial_candidate_checklist_evaluation_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"checklists",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_artificial_candidate_checklist_evaluations => (/:locale)/positions/:position_id/artificial_candidates/:artificial_candidate_id/checklists(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_artificial_candidate_checklist_evaluations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"checklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_candidate_email => (/:locale)/positions/:position_id/candidate_email(.:format)
  // function(position_id, options)
  position_candidate_email_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"candidate_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_candidates_application => (/:locale)/positions/:position_id/c/applications/:id(.:format)
  // function(position_id, id, options)
  position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_candidates_application_chat_messages => (/:locale)/positions/:position_id/c/applications/:application_id/chat(.:format)
  // function(position_id, application_id, options)
  position_candidates_application_chat_messages_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"chat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_candidates_application_new_upload => (/:locale)/positions/:position_id/c/applications/:application_id/new_upload(.:format)
  // function(position_id, application_id, options)
  position_candidates_application_new_upload_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"new_upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_candidates_application_upload_documents => (/:locale)/positions/:position_id/c/applications/:application_id/upload_documents(.:format)
  // function(position_id, application_id, options)
  position_candidates_application_upload_documents_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"upload_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_candidates_applications => (/:locale)/positions/:position_id/c/applications(.:format)
  // function(position_id, options)
  position_candidates_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_candidates_position_approaches => (/:locale)/positions/:position_id/c/position_approaches(.:format)
  // function(position_id, options)
  position_candidates_position_approaches_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"position_approaches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_data_position_recruiters_applications => (/:locale)/positions/:position_id/r/applications/position_data(.:format)
  // function(position_id, options)
  position_data_position_recruiters_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"position_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_email_customization => (/:locale)/positions/:position_id/email_customizations/:id(.:format)
  // function(position_id, id, options)
  position_email_customization_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"email_customizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_email_customizations => (/:locale)/positions/:position_id/email_customizations(.:format)
  // function(position_id, options)
  position_email_customizations_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"email_customizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_interview_date => (/:locale)/positions/:position_id/interview_dates/:id(.:format)
  // function(position_id, id, options)
  position_interview_date_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_dates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_interview_dates => (/:locale)/positions/:position_id/interview_dates(.:format)
  // function(position_id, options)
  position_interview_dates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_interview_reminder => (/:locale)/positions/:position_id/interview_reminders/:id(.:format)
  // function(position_id, id, options)
  position_interview_reminder_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_reminders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_interview_reminders => (/:locale)/positions/:position_id/interview_reminders(.:format)
  // function(position_id, options)
  position_interview_reminders_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_reminders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_job_posting => (/:locale)/positions/:position_id/job_posting(.:format)
  // function(position_id, options)
  position_job_posting_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"job_posting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_jobindex_publication => (/:locale)/positions/:position_id/jobindex_publication(.:format)
  // function(position_id, options)
  position_jobindex_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobindex_publication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication(.:format)
  // function(position_id, options)
  position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id(.:format)
  // function(position_id, id, options)
  position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_recruiters_application_assessment => (/:locale)/positions/:position_id/r/applications/:application_id/assessments/:id(.:format)
  // function(position_id, application_id, id, options)
  position_recruiters_application_assessment_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"assessments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
// position_recruiters_application_catalyst_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/catalyst_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_catalyst_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"catalyst_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_chat_messages => (/:locale)/positions/:position_id/r/applications/:application_id/chat(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_chat_messages_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"chat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_document => (/:locale)/positions/:position_id/r/applications/:application_id/documents/:id(.:format)
  // function(position_id, application_id, id, options)
  position_recruiters_application_document_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
// position_recruiters_application_documents => (/:locale)/positions/:position_id/r/applications/:application_id/documents(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_documents_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_hr_module_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/hr_module_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_hr_module_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"hr_module_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_quinyx_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/quinyx_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_quinyx_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"quinyx_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_sympa_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/sympa_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_sympa_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"sympa_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_tamigo_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/tamigo_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_tamigo_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"tamigo_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_application_two_people_integrations => (/:locale)/positions/:position_id/r/applications/:application_id/two_people_integrations(.:format)
  // function(position_id, application_id, options)
  position_recruiters_application_two_people_integrations_path: Utils.route([["locale",false],["position_id",true],["application_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"application_id",false],[2,[7,"/",false],[2,[6,"two_people_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_applications => (/:locale)/positions/:position_id/r/applications(.:format)
  // function(position_id, options)
  position_recruiters_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id(.:format)
  // function(position_id, id, options)
  position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_catalyst_integrations => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/catalyst_integrations(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_catalyst_integrations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"catalyst_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_document => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/documents/:id(.:format)
  // function(position_id, artificial_candidate_id, id, options)
  position_recruiters_artificial_candidate_document_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_documents => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/documents(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_documents_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_quinyx_integrations => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/quinyx_integrations(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_quinyx_integrations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"quinyx_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_sympa_integrations => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/sympa_integrations(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_sympa_integrations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"sympa_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_tamigo_integrations => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/tamigo_integrations(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_tamigo_integrations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"tamigo_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidate_two_people_integrations => (/:locale)/positions/:position_id/r/artificial_candidates/:artificial_candidate_id/two_people_integrations(.:format)
  // function(position_id, artificial_candidate_id, options)
  position_recruiters_artificial_candidate_two_people_integrations_path: Utils.route([["locale",false],["position_id",true],["artificial_candidate_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"artificial_candidate_id",false],[2,[7,"/",false],[2,[6,"two_people_integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates(.:format)
  // function(position_id, options)
  position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_redirect_organization => (/:locale)/organizations/:id/position_redirect(.:format)
  // function(id, options)
  position_redirect_organization_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"position_redirect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_round => (/:locale)/positions/:position_id/rounds/:id(.:format)
  // function(position_id, id, options)
  position_round_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// position_rounds => (/:locale)/positions/:position_id/rounds(.:format)
  // function(position_id, options)
  position_rounds_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// position_simplyjob_publication => (/:locale)/positions/:position_id/simplyjob_publication(.:format)
  // function(position_id, options)
  position_simplyjob_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"simplyjob_publication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// positions => (/:locale)/positions(.:format)
  // function(options)
  positions_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// preview_contract_document => (/:locale)/contract_documents/:id/preview(.:format)
  // function(id, options)
  preview_contract_document_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// preview_email_position_interview_reminders => (/:locale)/positions/:position_id/interview_reminders/preview_email(.:format)
  // function(position_id, options)
  preview_email_position_interview_reminders_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"interview_reminders",false],[2,[7,"/",false],[2,[6,"preview_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// preview_email_position_recruiters_applications => (/:locale)/positions/:position_id/r/applications/preview_email(.:format)
  // function(position_id, options)
  preview_email_position_recruiters_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"preview_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// preview_position => (/:locale)/positions/:id/preview(.:format)
  // function(id, options)
  preview_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// preview_position_job_posting => (/:locale)/positions/:position_id/job_posting/preview(.:format)
  // function(position_id, options)
  preview_position_job_posting_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"job_posting",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// preview_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/preview(.:format)
  // function(position_id, options)
  preview_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// print_position_job_posting => (/:locale)/positions/:position_id/job_posting/print(.:format)
  // function(position_id, options)
  print_position_job_posting_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"job_posting",false],[2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// proposal_to_candidate_contract_signing => (/:locale)/contract_signings/:id/proposal_to_candidate(.:format)
  // function(id, options)
  proposal_to_candidate_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"proposal_to_candidate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// publish_position => (/:locale)/positions/:id/publish(.:format)
  // function(id, options)
  publish_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// publish_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/publish(.:format)
  // function(position_id, options)
  publish_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// publish_update_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/publish_update(.:format)
  // function(position_id, options)
  publish_update_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"publish_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// quinyx_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/quinyx_integration(.:format)
  // function(position_id, id, options)
  quinyx_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"quinyx_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// quinyx_integration_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/quinyx_integration(.:format)
  // function(position_id, id, options)
  quinyx_integration_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"quinyx_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// recruiter_root => /(:locale)(.:format)
  // function(options)
  recruiter_root_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[1,[3,"locale",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reject_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/reject(.:format)
  // function(position_id, id, options)
  reject_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// reject_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/reject(.:format)
  // function(position_id, id, options)
  reject_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// reject_request_edit_request => (/:locale)/edit_requests/:id/reject_request(.:format)
  // function(id, options)
  reject_request_edit_request_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"edit_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// remove_from_category_category_artificial_saved_profile => (/:locale)/categories/:category_id/artificial_saved_profiles/:id/remove_from_category(.:format)
  // function(category_id, id, options)
  remove_from_category_category_artificial_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"artificial_saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove_from_category",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// remove_from_category_category_saved_profile => (/:locale)/categories/:category_id/saved_profiles/:id/remove_from_category(.:format)
  // function(category_id, id, options)
  remove_from_category_category_saved_profile_path: Utils.route([["locale",false],["category_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove_from_category",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// renew_expired_visma_contract_signing => (/:locale)/contract_signings/:id/renew_expired_visma(.:format)
  // function(id, options)
  renew_expired_visma_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renew_expired_visma",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// reopen_admin_position => /admin/positions/:id/reopen(.:format)
  // function(id, options)
  reopen_admin_position_path: Utils.route([["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reopen",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reopen_position => (/:locale)/positions/:id/reopen(.:format)
  // function(id, options)
  reopen_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reopen",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// reorder_rounds_position_round => (/:locale)/positions/:position_id/rounds/:id/reorder_rounds(.:format)
  // function(position_id, id, options)
  reorder_rounds_position_round_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"rounds",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_rounds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// request_documents_position_recruiters_artificial_candidates => (/:locale)/positions/:position_id/r/artificial_candidates/request_documents(.:format)
  // function(position_id, options)
  request_documents_position_recruiters_artificial_candidates_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[6,"request_documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// resend_invitation_email_hr_employees_datum => (/:locale)/hr/employees_data/:id/resend_invitation_email(.:format)
  // function(id, options)
  resend_invitation_email_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_invitation_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// reset_position_position => (/:locale)/positions/:id/reset_position(.:format)
  // function(id, options)
  reset_position_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// restore_default_translation_position_email_customization => (/:locale)/positions/:position_id/email_customizations/:id/restore_default_translation(.:format)
  // function(position_id, id, options)
  restore_default_translation_position_email_customization_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"email_customizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore_default_translation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// review_result_contract_signing => (/:locale)/contract_signings/:id/review_result(.:format)
  // function(id, options)
  review_result_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review_result",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// reviewer_review_contract_signing => (/:locale)/contract_signings/:id/reviewer_review(.:format)
  // function(id, options)
  reviewer_review_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviewer_review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// root => /(:locale)(.:format)
  // function(options)
  root_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[1,[3,"locale",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// saved_profile_comment => (/:locale)/saved_profiles/:saved_profile_id/comments/:id(.:format)
  // function(saved_profile_id, id, options)
  saved_profile_comment_path: Utils.route([["locale",false],["saved_profile_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// saved_profile_comments => (/:locale)/saved_profiles/:saved_profile_id/comments(.:format)
  // function(saved_profile_id, options)
  saved_profile_comments_path: Utils.route([["locale",false],["saved_profile_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"saved_profiles",false],[2,[7,"/",false],[2,[3,"saved_profile_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// send_application_position_candidates_application => (/:locale)/positions/:position_id/c/applications/:id/send_application(.:format)
  // function(position_id, id, options)
  send_application_position_candidates_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// send_contract_contract_signing => (/:locale)/contract_signings/:id/send_contract(.:format)
  // function(id, options)
  send_contract_contract_signing_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// send_to_editors_and_approvers_contract_signings => (/:locale)/contract_signings/send_to_editors_and_approvers(.:format)
  // function(options)
  send_to_editors_and_approvers_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"send_to_editors_and_approvers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// show_action_data_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/show_action_data(.:format)
  // function(position_id, id, options)
  show_action_data_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_action_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// show_action_data_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/show_action_data(.:format)
  // function(position_id, id, options)
  show_action_data_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_action_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// show_available_positions_category => (/:locale)/categories/:id/show_available_positions(.:format)
  // function(id, options)
  show_available_positions_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_available_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// show_cover_letter_position_application => (/:locale)/positions/:position_id/applications/:id/show_cover_letter(.:format)
  // function(position_id, id, options)
  show_cover_letter_position_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_cover_letter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// show_cover_letter_position_artificial_candidate => (/:locale)/positions/:position_id/artificial_candidates/:id/show_cover_letter(.:format)
  // function(position_id, id, options)
  show_cover_letter_position_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_cover_letter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// show_cv_position_application => (/:locale)/positions/:position_id/applications/:id/show_cv(.:format)
  // function(position_id, id, options)
  show_cv_position_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_cv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// show_cv_position_artificial_candidate => (/:locale)/positions/:position_id/artificial_candidates/:id/show_cv(.:format)
  // function(position_id, id, options)
  show_cv_position_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_cv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// show_invite_form_category => (/:locale)/categories/:id/show_invite_form(.:format)
  // function(id, options)
  show_invite_form_category_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_invite_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// show_pdf_position_artificial_candidate => (/:locale)/positions/:position_id/artificial_candidates/:id/show_pdf(.:format)
  // function(position_id, id, options)
  show_pdf_position_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// signers_contract_signings => (/:locale)/contract_signings/signers(.:format)
  // function(options)
  signers_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"signers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// statistics => (/:locale)/statistics(.:format)
  // function(options)
  statistics_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"statistics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// step_two_position => (/:locale)/positions/:id/step_two(.:format)
  // function(id, options)
  step_two_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"step_two",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// summary_contract_signings => (/:locale)/contract_signings/summary(.:format)
  // function(options)
  summary_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// sympa_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/sympa_integration(.:format)
  // function(position_id, id, options)
  sympa_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sympa_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// sympa_integration_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/sympa_integration(.:format)
  // function(position_id, id, options)
  sympa_integration_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sympa_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// tamigo_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/tamigo_integration(.:format)
  // function(position_id, id, options)
  tamigo_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"tamigo_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// tamigo_integration_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/tamigo_integration(.:format)
  // function(position_id, id, options)
  tamigo_integration_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"tamigo_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// thanks_for_apply_position_candidates_applications => (/:locale)/positions/:position_id/c/applications/thanks_for_apply(.:format)
  // function(position_id, options)
  thanks_for_apply_position_candidates_applications_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"c",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"thanks_for_apply",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]),
// two_people_integration_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/two_people_integration(.:format)
  // function(position_id, id, options)
  two_people_integration_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"two_people_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// two_people_integration_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/two_people_integration(.:format)
  // function(position_id, id, options)
  two_people_integration_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"two_people_integration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_address_hr_employees_datum => (/:locale)/hr/employees_data/:id/update_address(.:format)
  // function(id, options)
  update_address_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_brief_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/update_brief(.:format)
  // function(position_id, id, options)
  update_brief_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_brief",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_brief_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/update_brief(.:format)
  // function(position_id, id, options)
  update_brief_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_brief",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_cache_documents => (/:locale)/documents/update_cache(.:format)
  // function(options)
  update_cache_documents_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"update_cache",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// update_employment_information_hr_employees_datum => (/:locale)/hr/employees_data/:id/update_employment_information(.:format)
  // function(id, options)
  update_employment_information_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_employment_information",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_logo_position_jobnet_publication => (/:locale)/positions/:position_id/jobnet_publication/update_logo(.:format)
  // function(position_id, options)
  update_logo_position_jobnet_publication_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"jobnet_publication",false],[2,[7,"/",false],[2,[6,"update_logo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_media_module_settings_position => (/:locale)/positions/:id/update_media_module_settings(.:format)
  // function(id, options)
  update_media_module_settings_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_media_module_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_multiple_contract_signings => (/:locale)/contract_signings/update_multiple(.:format)
  // function(options)
  update_multiple_contract_signings_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"contract_signings",false],[2,[7,"/",false],[2,[6,"update_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// update_og_image_position_job_posting => (/:locale)/positions/:position_id/job_posting/update_og_image(.:format)
  // function(position_id, options)
  update_og_image_position_job_posting_path: Utils.route([["locale",false],["position_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"job_posting",false],[2,[7,"/",false],[2,[6,"update_og_image",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_onboarding_hr_person => (/:locale)/hr/people/:id/update_onboarding(.:format)
  // function(id, options)
  update_onboarding_hr_person_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_onboarding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_payroll_hr_employees_datum => (/:locale)/hr/employees_data/:id/update_payroll(.:format)
  // function(id, options)
  update_payroll_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_payroll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_photo_hr_employees_datum => (/:locale)/hr/employees_data/:id/update_photo(.:format)
  // function(id, options)
  update_photo_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_photo_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/update_photo(.:format)
  // function(position_id, id, options)
  update_photo_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_positions_job_posting_layout_widgets => (/:locale)/job_posting_layouts/:job_posting_layout_id/widgets/update_positions(.:format)
  // function(job_posting_layout_id, options)
  update_positions_job_posting_layout_widgets_path: Utils.route([["locale",false],["job_posting_layout_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"job_posting_layouts",false],[2,[7,"/",false],[2,[3,"job_posting_layout_id",false],[2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"update_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_positions_organization_page_widgets => (/:locale)/organization_pages/:organization_page_id/widgets/update_positions(.:format)
  // function(organization_page_id, options)
  update_positions_organization_page_widgets_path: Utils.route([["locale",false],["organization_page_id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"organization_pages",false],[2,[7,"/",false],[2,[3,"organization_page_id",false],[2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"update_positions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// update_weights_position => (/:locale)/positions/:id/update_weights(.:format)
  // function(id, options)
  update_weights_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_weights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// update_work_dates_position_recruiters_application => (/:locale)/positions/:position_id/r/applications/:id/update_work_dates(.:format)
  // function(position_id, id, options)
  update_work_dates_position_recruiters_application_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_work_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_work_dates_position_recruiters_artificial_candidate => (/:locale)/positions/:position_id/r/artificial_candidates/:id/update_work_dates(.:format)
  // function(position_id, id, options)
  update_work_dates_position_recruiters_artificial_candidate_path: Utils.route([["locale",false],["position_id",true],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"position_id",false],[2,[7,"/",false],[2,[6,"r",false],[2,[7,"/",false],[2,[6,"artificial_candidates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_work_dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]),
// update_work_information_hr_employees_datum => (/:locale)/hr/employees_data/:id/update_work_information(.:format)
  // function(id, options)
  update_work_information_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_work_information",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// upload_file_hr_employees_datum => (/:locale)/hr/employees_data/:id/upload_file(.:format)
  // function(id, options)
  upload_file_hr_employees_datum_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"hr",false],[2,[7,"/",false],[2,[6,"employees_data",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// user_root => /(:locale)(.:format)
  // function(options)
  user_root_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[7,"/",false],[2,[1,[3,"locale",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// verify_current_timeslot => (/:locale)/timeslots/:id/verify_current(.:format)
  // function(id, options)
  verify_current_timeslot_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"timeslots",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"verify_current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// verify_timeslots => (/:locale)/timeslots/verify(.:format)
  // function(options)
  verify_timeslots_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"timeslots",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// video_request => (/:locale)/video_requests/:id(.:format)
  // function(id, options)
  video_request_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"video_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// video_requests => (/:locale)/video_requests(.:format)
  // function(options)
  video_requests_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"video_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// view_as_candidate_position => (/:locale)/positions/:id/view_as_candidate(.:format)
  // function(id, options)
  view_as_candidate_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"view_as_candidate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// visma_callback_documents_signed => (/:locale)/visma_callbacks/documents_signed(.:format)
  // function(options)
  visma_callback_documents_signed_path: Utils.route([["locale",false],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"visma_callbacks",false],[2,[7,"/",false],[2,[6,"documents_signed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// weights_position => (/:locale)/positions/:id/weights(.:format)
  // function(id, options)
  weights_position_path: Utils.route([["locale",false],["id",true],["format",false]], {"host":"app.elvium.com"}, [2,[1,[2,[7,"/",false],[3,"locale",false]],false],[2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"weights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

const routingObject = routes.Routes;

const changeRouteLocale = () => {
  const locale = document.querySelector('body').dataset.currentLocale;
  routingObject.configure({
    default_url_options: {
      locale,
    }
  });
}

export {
  routingObject as Routes,
  changeRouteLocale
};
